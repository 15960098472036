import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),

  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login/index')
    },
    {
      path: '/home',
      name: 'home',
      meta: { title: '首页' },
      component: () => import('../views/Home/index')
    },
    {
      path: '/system',
      name: 'system',
      meta: { title: '驾驶舱' },
      component: () => import('../views/System/index')
    },

    {
      path: '/',
      redirect: '/login',
    },
    {//单位注册
      path: '/register',
      name: 'register',
      component: () => import('../views/Register/index')
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
  ]
})



export default router
